import {
  GetStaticPaths,
  GetStaticPathsContext,
  GetStaticPathsResult,
  GetStaticProps,
  GetStaticPropsContext,
} from 'next';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';
import SinglePost from '../components/_template';
import {
  fetchNavigations,
  fetchEntries,
  fetchEntry,
  fetchSubsites,
  PreviewData,
} from '../lib/api';
import { useTranslation } from '../lib/hooks.context';
import { Post, SiteNav } from '../types';

const Loader = dynamic(() => import('../components/Loader'));
const ErrorPage = dynamic(() => import('../components/_template/ErrorPage'));

const Entry = ({ data }: { data?: Post }) => {
  const t = useTranslation();
  if (!data) {
    return <Loader size="lg" speed=".666s" mt="10%" />;
  }
  try {
    return <SinglePost post={data} />;
  } catch (error) {
    return <ErrorPage>{t('error_generic')}</ErrorPage>;
  }
};
export default Entry;

interface LocaleEntry {
  locale: string;
  entries: (Pick<Post, 'ID' | 'url'> | string)[];
}
interface SitePayload {
  site: string;
  localeEntries: LocaleEntry[];
}

type PathType = GetStaticPathsResult<{
  slug: string;
  entry: string[];
}>['paths'][0];

// We should improve the speed of this process at some point, but preferably when it's running in azure. Following variables are for that
const NS_PER_SEC = 1e9;

export const getStaticPaths: GetStaticPaths = async ({
  locales,
}: GetStaticPathsContext) => {
  try {
    if (process.env.NODE_ENV === 'development') {
      console.info('Skipping getStaticPaths in development mode');

      return {
        paths: [],
        fallback: true,
      };
    }
    const start = process.hrtime();
    console.info('START PATHS FETCHING');

    const sites = await fetchSubsites({});

    let diff = process.hrtime(start);
    const checkpoint = process.hrtime();
    console.info(
      `Sites took ${(diff[0] * NS_PER_SEC + diff[1]) / NS_PER_SEC} seconds`,
    );

    // TODO: refactor this to first loop locales, then sites within for each locale and then entries for each site's each locale
    const siteData: SitePayload[] = await Promise.all(
      sites.map(async ({ path }) => {
        const site: string = path.replace(/\//g, '');

        return (locales || []).reduce<Promise<SitePayload>>(
          async (acc, locale) => {
            /* NOTE: Skip static generation for subsites at least for now
             * In total all the subsites' pages would be in thousands. The build takes probably 45 minutes then
             * Maybe add an env flag that allows static generation of them all at some point, but should definitely not default to that.
             *
             * Caching all the shared pages to disc and using them instead of fetching all of them again and again, would be something too, HOWEVER
             * it is not viable solution, because the contents are different at least for the links and some custom bits, so it's a no go too.
             */
            const entries = site
              ? []
              : await fetchEntries({ site, lang: locale });
            const prev = await acc;

            return {
              ...prev,
              localeEntries: [...prev.localeEntries, { entries, locale }],
            };
          },
          Promise.resolve({ site, localeEntries: [] }),
        );
      }),
    );

    diff = process.hrtime(checkpoint);
    console.info(
      `Entries took ${(diff[0] * NS_PER_SEC + diff[1]) / NS_PER_SEC} seconds`,
    );

    const paths: GetStaticPathsResult['paths'] = siteData.reduce<PathType[]>(
      (pathsAcc, { localeEntries, site }) =>
        localeEntries.reduce(
          (acc, { entries, locale }) =>
            entries.reduce((bacc, entry) => {
              const url = typeof entry === 'string' ? entry : entry.url;
              if (!url) {
                return bacc;
              }
              /*
               * All entries of a site must resolve to that site. Either WP has done this for us (it should/does) or we need to enforce it.
               */
              const slug = !site || url.includes(site) ? url : `/${site}${url}`;

              return [
                ...bacc,
                {
                  params: {
                    slug,
                    entry: slug.split('/').filter(Boolean),
                  },
                  locale,
                },
              ];
            }, acc),
          pathsAcc,
        ),
      [],
    );

    console.info('PATHS DONE');

    return {
      paths,
      fallback: true,
    };
  } catch (error) {
    console.error(`Error during getStaticPaths was caught: `, error);
    Sentry.captureException(error, {
      extra: { cause: 'Error during getStaticPaths in [...entry]' },
    });

    return {
      paths: [],
      fallback: true,
    };
  }
};

export const emptyNav: SiteNav = {
  main_navigation: [],
  legal_navigation: [],
  social_media_links: [],
  announcement_bar: null,
  header_links: {
    login: {
      title: 'Kirjaudu OmaMetsään',
      url: 'https://www.omametsa.mhy.fi/',
    },
    membership: null,
  },
};

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  defaultLocale,
  preview,
  previewData,
}: Omit<GetStaticPropsContext, 'previewData'> & {
  previewData?:
    | PreviewData
    | Omit<PreviewData, 'mhy_preview_id' | 'mhy_preview_nonce'>;
}) => {
  try {
    const entryPath = Array.isArray(params?.entry)
      ? params?.entry.join('/')
      : params?.entry;

    const allSites = (await fetchSubsites({ lang: locale })) || [];

    const sites = !locale
      ? allSites
      : allSites.filter((site) => site.languages?.includes(locale));

    // first part of path might be subsite
    const maybeSubsite = entryPath?.split('/')[0];

    // find out if first part of the slug is actually subsite
    const site = sites.reduce((acc, { path }) => {
      const siteSlug: string = path.replace(/\//g, '');
      if (maybeSubsite === siteSlug) {
        return siteSlug;
      }
      return acc;
    }, '');

    const navigationsP = fetchNavigations({
      site,
      lang: locale,
      previewData:
        !previewData || (previewData as PreviewData)?.mhy_preview_nonce
          ? null
          : previewData,
    });

    // if subsite, dont include that in slug
    const slug = site
      ? entryPath
          ?.split('/')
          .filter((s) => s !== site)
          .join('/')
      : entryPath;

    const dataP = fetchEntry({
      site,
      slug: slug || 'home',
      lang: locale,
      previewData,
    });

    const [navigations, data] = await Promise.all([navigationsP, dataP]);

    if (!data) {
      if (!navigations) {
        // No post, no nav, API is down.

        if (process.env.RUNNING_BUILD) {
          /* Typically it's okay to throw so site stay stale, however during build we dont want this.
           * Reason being that if the WP service goes down due to mistake in FPM config or something,
           * the fix wont get build because it's built against the production which now wont respond, therefore
           * fix cant get deployed as it's breaking the fix build. Lovely.
           */
          return {
            notFound: true,
            revalidate: 60,
          };
        }

        throw new Error('Stale while no data');
      }

      const issueMsg = `Entry not found: ${slug}${
        site ? `, of site: ${site}` : ''
      }`;
      console.warn(issueMsg);
      Sentry.captureMessage(issueMsg);
      return {
        props: {
          navigations: navigations || emptyNav,
          sites,
          subsite: site || null,
        },
        notFound: true,
        revalidate: 60,
      };
    }

    const { default: translationData } = await import(
      `../lib/translations/${locale}`
    ).catch(async () => {
      if (locale === defaultLocale) {
        console.error('No translations found');
        Sentry.captureMessage('No translations found');

        return { default: null };
      }
      console.warn(
        `No translations found for locale: ${locale}, falling back to defaultLocale`,
      );

      const fallbackModule = await import(
        `../lib/translations/${defaultLocale}`
      ).catch(() => ({ default: null }));
      return fallbackModule;
    });

    const {
      meta = null,
      translations = null,
      edit_link = null,
      ...post
    } = data;

    return {
      props: {
        data: post,
        metadata: {
          ...meta,
          url: post.url,
          canonical: meta?.canonical,
        },
        editLink: edit_link,
        navigations,
        allSites,
        sites,
        subsite: site,
        translationData,
        translations,
        isPreview:
          !!preview && !!(previewData as PreviewData)?.mhy_preview_nonce,
        isAdminMode:
          !!preview && !(previewData as PreviewData)?.mhy_preview_nonce,
      },
      revalidate: 300,
    };
  } catch (error) {
    console.error('Error fetching Entry data', error);
    Sentry.captureException(error, {
      extra: { cause: 'Error during getStaticProps in [...entry]' },
    });

    // It's still OK to throw error during build if the issue is caused by something other than WP API not responding at all
    throw error;
  }
};
