/*
 * Basically equal to WP's single.php - The root that decides how Post is rendered, what layout it gets.
 * Template choosing should be done based on eg. post_type, template
 */
import dynamic from 'next/dynamic';
import {
  EventPost,
  EventsPagePost,
  PeoplePagePost,
  BlogsPage,
  Post,
  Article as ArticleProps,
  AssociationPage as AssociationPageProps,
  NewsPage,
  JobsPage,
  ServicePage as ServicePageProps,
  BenefitsPage as BenefitsPageProps,
} from '../../types';

const Article = dynamic(() => import('./Article'));
const Page = dynamic(() => import('./Page'));
const LegalPage = dynamic(() => import('./LegalPage'));
const PeoplePage = dynamic(() => import('./PeoplePage'));
const EventListingPage = dynamic(() => import('./EventListing'));
const NewsListingPage = dynamic(() => import('./NewsListing'));
const BlogListingPage = dynamic(() => import('./BlogListing'));
const ServiceLanding = dynamic(() => import('./ServiceLanding'));
const FrontPage = dynamic(() => import('./FrontPage'));
const ServicePage = dynamic(() => import('./ServicePage'));
const MyForest = dynamic(() => import('./MyForest'));
const ElectionPage = dynamic(() => import('./ElectionPage'));
const CampaignPage = dynamic(() => import('./CampaignPage'));
const EventPage = dynamic(() => import('./EventPage'));
const AssociationPage = dynamic(() => import('./AssociationPage'));
const JobListingPage = dynamic(() => import('./JobListing'));
const BenefitsPage = dynamic(() => import('./BenefitsPage'));
const ContactPage = dynamic(() => import('./ContactPage'));

const Single = ({ post }: { post: Post }) => {
  if (!post) {
    return null;
  }
  if (post.template) {
    switch (post.template) {
      case 'front-page':
        return <FrontPage {...post} />;
      case 'service-landing':
        return <ServiceLanding {...post} />;
      case 'my-forest':
        return <MyForest {...post} />;
      case 'campaign':
        return <CampaignPage {...post} />;
      case 'person-listing':
        return <PeoplePage {...(post as PeoplePagePost)} />;
      case 'event-listing':
        return <EventListingPage {...(post as EventsPagePost)} />;
      case 'news-listing':
        return <NewsListingPage {...(post as NewsPage)} />;
      case 'blog-listing':
        return <BlogListingPage {...(post as BlogsPage)} />;
      case 'association-page':
        return <AssociationPage {...(post as AssociationPageProps)} />;
      case 'job-listing':
        return <JobListingPage {...(post as JobsPage)} />;
      case 'membership-benefits-page':
        return <BenefitsPage {...(post as BenefitsPageProps)} />;
      case 'contact-page':
        return <ContactPage {...post} />;
      case 'legal-page':
        return <LegalPage {...post} />;
      default:
        /* void */
        break;
    }
  }
  switch (post.post_type) {
    case 'shared-election-page':
      return <ElectionPage {...post} />;
    case 'post':
    case 'blog':
      return <Article {...(post as ArticleProps)} />;
    case 'event':
      return <EventPage {...(post as EventPost)} />;
    case 'service-page':
      return <ServicePage {...(post as ServicePageProps)} />;
    default:
      /* void */
      break;
  }

  return <Page {...post} />;
};

export default Single;
